import React from "react";

export function LogoRotating({ width }: { width: number }) {
  return (
    <svg
      width={width}
      viewBox="0 0 213 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="group overflow-visible [&>path]:fill-primary cursor-pointer hover:bg-primary p-2 "
    >
      <path
        className="group-hover:fill-secondary"
        d="M6.52005 104.018C17.8801 98.906 21.5721 93.368 21.5721 81.156V32.734C21.5721 14.842 17.8801 9.30399 6.52005 4.19199L7.08805 0.215988C12.7681 0.783989 16.1761 1.20998 20.0101 1.20998C23.5601 1.20998 27.1101 0.641991 30.3761 0.0739899L101.518 83.996V27.054C101.518 14.842 98.5361 9.30399 84.3361 4.19199L84.9041 0.215988C94.4181 1.06799 99.9561 1.20998 104.358 1.20998C108.76 1.20998 114.014 0.925987 120.972 0.215988L121.54 4.19199C110.18 9.44599 107.198 14.842 107.198 27.054V107.142L102.796 109.698L27.2521 20.522V81.156C27.2521 93.368 30.2341 98.764 44.4341 104.018L43.8661 107.994C34.6361 107.284 28.1041 107 23.1341 107C17.7381 107 15.0401 107.284 7.08805 107.994L6.52005 104.018Z"
        fill="#C9D4C6"
      />
      <path
        d="M171.404 109.13C148.968 109.13 130.792 91.664 130.792 67.808C130.792 45.088 148.968 26.77 171.404 26.77C193.698 26.77 212.016 44.094 212.016 67.808C212.016 90.67 193.698 109.13 171.404 109.13ZM183.758 101.178C196.254 96.918 201.65 78.6 194.266 59.146C187.024 40.118 170.978 30.746 158.766 34.864C146.412 39.266 141.158 57.3 148.4 76.47C155.784 95.782 171.688 105.438 183.758 101.178Z"
        fill="#C9D4C6"
        className="group-hover:fill-secondary animate-spinfast origin-center [transform-box:fill-box]"
      />
      <path
        className="group-hover:fill-secondary"
        d="M4.81605 131.002C14.7561 123.476 25.2641 119.074 39.6061 119.074C53.3801 119.074 63.3201 123.618 69.8521 133.274C77.6621 129.724 84.7621 127.168 90.5841 125.606L93.1401 128.73C80.2181 136.966 75.3901 146.906 79.2241 175.59C81.9221 195.47 89.7321 215.208 98.9621 222.308L97.1161 226H74.3961C73.6861 215.492 72.5501 206.262 69.2841 183.4C56.0781 225.148 18.7321 233.384 6.66205 224.722C-2.70995 217.764 -3.41995 191.494 23.7021 164.23C32.7901 155.426 45.0021 146.338 57.9241 139.238C47.8421 125.748 30.0921 123.76 6.80405 134.978L4.81605 131.002ZM19.3001 212.794C28.3881 218.9 44.5761 210.238 55.0841 196.606C62.4681 186.95 68.1481 176.3 66.1601 161.248C65.1661 154.432 63.4621 148.752 60.9061 143.924C48.4101 151.45 37.1921 159.97 29.5241 168.064C16.1761 182.122 10.4961 206.972 19.3001 212.794Z"
        fill="#C9D4C6"
      />
      <path
        className="group-hover:fill-secondary"
        d="M164.24 223.018C174.18 219.326 177.162 213.788 177.162 202.996V179.566C177.162 164.23 171.34 156.704 159.696 156.704C147.626 156.704 140.81 164.798 134.562 173.886V202.996C134.562 213.788 137.544 219.184 148.904 223.018L148.336 226.994C141.378 226.284 132.858 226 128.314 226C123.77 226 116.67 226.284 108.008 226.994L107.44 223.018C118.8 219.326 121.782 213.788 121.782 202.996V147.474C121.782 136.682 118.516 127.594 108.008 127.594V124.186C122.634 121.488 130.586 116.234 137.828 107.856L140.384 109.986C135.982 120.92 134.562 132.422 134.562 143.214V167.638C142.514 153.58 155.436 145.77 166.228 145.77C182.132 145.77 189.942 156.846 189.942 178.998V202.996C189.942 213.788 192.924 219.184 204.284 223.018L203.716 226.994C196.758 226.284 188.238 226 183.694 226C179.15 226 172.05 226.284 164.808 226.994L164.24 223.018Z"
        fill="#C9D4C6"
      />
    </svg>
  );
}

export function LogoLoading({ width }: { width: number }) {
  return (
    <svg
      width={width}
      viewBox="120 0 100 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className=" overflow-visible "
    >
      {/* <path
        className="group-hover:fill-secondary"
        d="M6.52005 104.018C17.8801 98.906 21.5721 93.368 21.5721 81.156V32.734C21.5721 14.842 17.8801 9.30399 6.52005 4.19199L7.08805 0.215988C12.7681 0.783989 16.1761 1.20998 20.0101 1.20998C23.5601 1.20998 27.1101 0.641991 30.3761 0.0739899L101.518 83.996V27.054C101.518 14.842 98.5361 9.30399 84.3361 4.19199L84.9041 0.215988C94.4181 1.06799 99.9561 1.20998 104.358 1.20998C108.76 1.20998 114.014 0.925987 120.972 0.215988L121.54 4.19199C110.18 9.44599 107.198 14.842 107.198 27.054V107.142L102.796 109.698L27.2521 20.522V81.156C27.2521 93.368 30.2341 98.764 44.4341 104.018L43.8661 107.994C34.6361 107.284 28.1041 107 23.1341 107C17.7381 107 15.0401 107.284 7.08805 107.994L6.52005 104.018Z"
        fill="#C9D4C6"
      /> */}
      <path
        d="M171.404 109.13C148.968 109.13 130.792 91.664 130.792 67.808C130.792 45.088 148.968 26.77 171.404 26.77C193.698 26.77 212.016 44.094 212.016 67.808C212.016 90.67 193.698 109.13 171.404 109.13ZM183.758 101.178C196.254 96.918 201.65 78.6 194.266 59.146C187.024 40.118 170.978 30.746 158.766 34.864C146.412 39.266 141.158 57.3 148.4 76.47C155.784 95.782 171.688 105.438 183.758 101.178Z"
        fill="#C9D4C6"
        className="animate-spinfastloading origin-center [transform-box:fill-box]"
      />
      {/* <path
        className="group-hover:fill-secondary"
        d="M4.81605 131.002C14.7561 123.476 25.2641 119.074 39.6061 119.074C53.3801 119.074 63.3201 123.618 69.8521 133.274C77.6621 129.724 84.7621 127.168 90.5841 125.606L93.1401 128.73C80.2181 136.966 75.3901 146.906 79.2241 175.59C81.9221 195.47 89.7321 215.208 98.9621 222.308L97.1161 226H74.3961C73.6861 215.492 72.5501 206.262 69.2841 183.4C56.0781 225.148 18.7321 233.384 6.66205 224.722C-2.70995 217.764 -3.41995 191.494 23.7021 164.23C32.7901 155.426 45.0021 146.338 57.9241 139.238C47.8421 125.748 30.0921 123.76 6.80405 134.978L4.81605 131.002ZM19.3001 212.794C28.3881 218.9 44.5761 210.238 55.0841 196.606C62.4681 186.95 68.1481 176.3 66.1601 161.248C65.1661 154.432 63.4621 148.752 60.9061 143.924C48.4101 151.45 37.1921 159.97 29.5241 168.064C16.1761 182.122 10.4961 206.972 19.3001 212.794Z"
        fill="#C9D4C6"
      />
      <path
        className="group-hover:fill-secondary"
        d="M164.24 223.018C174.18 219.326 177.162 213.788 177.162 202.996V179.566C177.162 164.23 171.34 156.704 159.696 156.704C147.626 156.704 140.81 164.798 134.562 173.886V202.996C134.562 213.788 137.544 219.184 148.904 223.018L148.336 226.994C141.378 226.284 132.858 226 128.314 226C123.77 226 116.67 226.284 108.008 226.994L107.44 223.018C118.8 219.326 121.782 213.788 121.782 202.996V147.474C121.782 136.682 118.516 127.594 108.008 127.594V124.186C122.634 121.488 130.586 116.234 137.828 107.856L140.384 109.986C135.982 120.92 134.562 132.422 134.562 143.214V167.638C142.514 153.58 155.436 145.77 166.228 145.77C182.132 145.77 189.942 156.846 189.942 178.998V202.996C189.942 213.788 192.924 219.184 204.284 223.018L203.716 226.994C196.758 226.284 188.238 226 183.694 226C179.15 226 172.05 226.284 164.808 226.994L164.24 223.018Z"
        fill="#C9D4C6"
      /> */}
    </svg>
  );
}
